$(document).on('turbolinks:load', function() {

	//-----------------
	//Single Job Search
	//-----------------
	
	$(document).ready(function() {
		if($('.job_search').length>0){
			search_job();
		}
	});
	
	function search_job(){
	    var q = $('.job_search').val();
	    $.ajax({
	        type: "GET",
	        url: "/tbljobs/search",
	        dataType: "json",
	        data: {'keyword': q},
	        success: function(result){
	            $(".job_select").empty();
	            if($(".job_search").val().length <= 0){
			    	$(".job_select").prepend('<option value = "">No Job Selected<option>');
			    }	            
	            for(res in result){
	            	render = false;
	            	$(".job_select").append('<option value='+ result[res].ID +'>'+ result[res].SubandLot +'</option>');
	            }
	        }
	    });   
	}
	
	$(function() { 
		$('.job_search').keyup(function(){
			search_job();
		});
	});
	
	//----------------
	//Multi Job Search
	//----------------
	
	$(document).ready(function() {
		if($('.multi_job_search').length>0){
			search_multi_job();
		}
	});
	
	function search_multi_job(){
		var sub = $('.job_subdivision').val();
		var phase = $('.job_phase').val();
		var lot = $('.job_lot').val();
		var addr = $('.job_address').val();
		var bldg = $('.job_building').val();
		$.ajax({
	        type: "GET",
	        url: "/tbljobs/search_multiple",
	        dataType: "json",
	        data: {'sub': sub, 'phase': phase, 'lot': lot, 'addr': addr, 'bldg': bldg},
	        success: function(result){
	            $(".multi_job_select").empty();
	            for(res in result){
	            	render = false;
	            	optionStr = "";
	            	if(result[res].Subdivision != null){
	            		optionStr = result[res].Subdivision;
	            		if(result[res].Phase != null && result[res].Phase > 2){
	            			optionStr = optionStr + " PH " + result[res].Phase.toString();
	            		}
	            		if(result[res].LotNumber != null){
	            			optionStr = optionStr + " - Lot " + result[res].LotNumber.toString();
	            		}
	            		if(result[res].APTBuildingNumber != null){
	            			optionStr = optionStr + " - Bldg: " + result[res].APTBuildingNumber.toString();
	            		}
	            	} else if(result[res].AddressLine1 != null) {
	            		optionStr = result[res].AddressLine1.toString();
	            	}
	            	$(".multi_job_select").append('<option value='+ result[res].ID +'>'+ optionStr +'</option>');
	            }
	        }
	    }); 
	}
	
	$(function() { 
		$('.multi_job_search').keyup(function(){
			search_multi_job();
		});
	});
	
	//--------------------------
	//Subdivision + Phase Search
	//--------------------------
	
	$(document).ready(function() {
		if($('.sub_search').length>0){
			search_sub();
		}
	});
	
	function search_sub(){
		var q = $('.sub_search').val();
	    $.ajax({
	        type: "GET",
	        url: "/tblsubdivisions/search",
	        dataType: "json",
	        data: {'keyword': q},
	        success: function(result){
	            $(".sub_select").empty();
	            if($(".sub_search").val().length <= 0){
			    	$(".sub_select").prepend('<option value = "">No Subdivision Selected<option>');
			    }	            
	            for(res in result){
	            	render = false;
	            	$(".sub_select").append('<option value='+ result[res].ID +'>'+ result[res].SubPhase +'</option>');
	            }
	        }
	    }); 
	}
	
	$(function() { 
		$('.sub_search').keyup(function(){
			search_sub();
		});
	});
	
});